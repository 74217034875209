@import url("https://fonts.googleapis.com/css2?family=Encode+Sans:wght@700&family=Fuzzy+Bubbles:wght@700&display=swap");

body {
  margin: auto;
  margin-top: -30px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: rgb(1, 1, 20);
}

.pageHeader {
  color: whitesmoke;
  text-align: center;
  justify-content: center;
  text-decoration: underline;
  text-underline-offset: 10px;
}

.projectsHeader {
  margin-top: 100px;
}

html {
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(42, 138, 248);
  outline: 1px solid rgb(42, 138, 248);
  border-radius: 10px;
}

html {
  overflow-y: auto;
  scrollbar-color: rgb(42, 138, 248) rgba(0,0,0,0.3);
  scrollbar-width: thin;
}