:root {
  --theme: rgb(42, 138, 248);
  --generalColor: whitesmoke;
}

.contact {
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 90vw;
  margin: 80px auto;
  gap: 50px;
  color: var(--generalColor);
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

/* Optional */
/* .contactInfo {
  width: 45vw;
  background-color: rgb(23, 73, 130);
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.2),
    2px 2px 5px rgba(255, 255, 255, 0.3);
  background: url(../imgs/contactbg2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
} */

.locationMessage p {
  font-size: 20px;
}

.cv {
  height: 45px;
  font-size: 20px;
  background-color: rgb(4, 36, 73);
  width: 25vw;
  margin: 200px auto;
  display: flex;
  justify-content: center;
  box-shadow: 0 0 8px var(--generalColor);
  border-radius: 12px;
  border: 1px solid var(--generalColor);
  align-items: center;
  text-decoration: none;
  color: whitesmoke;
}

.cv:hover {
  box-shadow: 0 0 12px var(--generalColor);
  transition: 0.3s;
}

.cv:hover a {
  color: var(--theme);
  transition: 0.3s;
}

.cv a {
  text-decoration: none;
  color: var(--generalColor);
  display: flex;
  align-items: center;
}

.locationMessage {
  color: var(--generalColor);
}

.location {
  border: 1px solid var(--generalColor);
  color: var(--generalColor);
  height: 45px;
  font-size: 16px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: rgb(4, 36, 73);
  box-shadow: 0 0 8px var(--generalColor);
}

.message {
  color: var(--generalColor);
  font-size: 20px;
  word-spacing: 10px;
}

.message p span {
  color: var(--theme);
}

.contactForm {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 50px;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}

.contactForm input {
  height: 30px;
  border-radius: 2px;
  box-shadow: 0 0 1px var(--generalColor);
  background-color: rgb(4, 36, 73);
  color: var(--generalColor);
  font-weight: bolder;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  padding-left: 10px;
}

.contactForm textarea {
  height: 200px;
  border-radius: 2px;
  box-shadow: 0 0 1px var(--generalColor);
  background-color: rgb(4, 36, 73);
  color: var(--generalColor);
  font-weight: bolder;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  padding-left: 10px;
  padding-top: 5px;
}

.contactForm button {
  height: 28px;
  border-radius: 2px;
  background-color: rgb(4, 36, 73);
  color: var(--generalColor);
  font-weight: bold;
  font-size: 20px;
  box-shadow: 0 0 2px var(--generalColor);
}

.contactForm button:hover {
  box-shadow: 0 0 4px var(--generalColor);
  color: var(--theme);
  transition: 0.3s;
}

.contactForm input:hover,
.contactForm textarea:hover {
  box-shadow: 0 0 4px var(--generalColor);
  transition: 0.3s;
}

.submitBtn {
  cursor: pointer;
}

@media (max-width: 769px) {
  .contactInfo {
    width: 90vw;
  }

  .cv {
    width: 60vw;
    font-size: 18px;
  }

  .location {
    font-size: 16px;
  }

  .message p {
    font-size: 16px;
  }

  .locationMessage p {
    font-size: 16px;
  }

  .contact {
    grid-template-columns: 1fr;
    width: 90vw;
    margin: 80px auto;
    gap: 50px;
  }
}

@media (max-width: 480px) {
  .contactInfo {
    width: 90vw;
  }

  .cv {
    width: 70vw;
    font-size: 16px;
  }

  .location {
    font-size: 14px;
  }

  .contact {
    grid-template-columns: 1fr;
    width: 90vw;
    margin: 80px auto;
    gap: 50px;
  }
}

@media (max-width: 280px) {
  .contactInfo {
    width: 90vw;
  }

  .cv {
    width: 70vw;
    font-size: 14px;
  }

  .location {
    font-size: 12px;
  }

  .message p {
    font-size: 12px;
  }

  .locationMessage p {
    font-size: 12px;
  }

  .contact {
    grid-template-columns: 1fr;
    width: 90vw;
    margin: 80px auto;
    gap: 50px;
  }
}

/* User message */
#userMessage {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 250px; /* Set a default minimum width */
  margin-left: -125px; /* Divide value of min-width by 2 */
  color: var(--generalColor); /* White text color */
  background-color: var(--theme);
  text-align: center; /* Centered text */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 6; /* Add a z-index if needed */
  left: 50%; /* Center the userMessage */
  bottom: 30px; /* 30px from the bottom */
  border-radius: 14px;
}

/* Show the userMessage when clicking on a button (class added with JavaScript) */
#userMessage.show {
  visibility: visible; /* Show the userMessage */
  /* Add animation: Take 0.5 seconds to fade in and out the userMessage.
    However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the userMessage in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
