:root {
    --fontColor: whitesmoke;
}

.skillsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: var(--fontColor);
    width: 90vw;
    margin: 50px auto;
    gap: 30px;
}

.skillsContainer section h2 {
    color: rgb(42, 138, 248);
}

.skillItem {
    height: 40px;
    border: 1px solid whitesmoke;
    margin: 25px 0;
    background-color: rgb(4, 36, 73);
    box-shadow: 0 0 8px whitesmoke;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding-left: 14px;
}

.skillItem span {
    padding-left: 10px;
}

@media (max-width: 769px) {
    .skillsContainer {
        grid-template-columns: 1fr;
        height: auto;
    }
}

.html {
    width: 95%;
}

.css {
    width: 90%;
}

.postcss {
    width: 75%;
}

.javascript {
    width: 90%;
}

.typescript {
    width: 80%;
}

.react {
    width: 85%;
}

.tsql {
    width: 90%;
}

.postgresql {
    width: 85%;
}

.dynamodb {
    width: 70%;
}

.supabase {
    width: 80%;
}

.jest {
    width: 80%;
}

.reactTestingLibrary {
    width: 75%;
}

.storybook {
    width: 70%;
}

.python {
    width: 80%;
}

.powerbi {
    width: 75%;
}

.graphql {
    width: 75%;
}

.git {
    width: 85%;
}

.mui {
    width: 83%;
}

.bootstrap {
    width: 80%;
}
