.whyUsContainer {
  padding-top: 20px;
  color: whitesmoke;
  margin-bottom: 200px;
  font-family: Arial, Helvetica, sans-serif;
}

.whyUs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  padding-top: 80px;
  gap: 40px;
  width: 80vw;
}

.whyUsItem {
  display: flex;
  justify-content: center;
}

.whyUsItem div h6 {
  align-items: center;
  text-align: left;
  padding-left: 30px;
  font-size: 28px;
  margin-bottom: 40px;
  color: rgb(42, 138, 248);
}

.whyUsItem div section {
  font-size: 22px;
  width: 30vw;
  text-align: justify;
  padding-left: 30px;
}

.whyUsImg {
  font-size: 40px;
  padding-top: 60px;
  color: rgb(42, 138, 248);
}

/* Dynamics */

@media (max-width: 768px) {
  /* Clients2 */
  .whyUs {
    display: grid;
    grid-template-columns: 1fr;
    margin: 0px auto;
    padding-top: 30px;
    gap: 40px;
    width: 75vw;
  }

  .whyUsItem {
    width: 75vw;
  }

  .whyUsItem div {
    padding-top: 7px;
  }

  .whyUsItem div h6 {
    font-size: 30px;
    text-align: left;
    padding-left: 20px;
  }

  .whyUsItem div section {
    font-size: 18px;
    text-align: justify;
    padding-left: 20px;
    width: 70vw;
  }

  .whyUsImg {
    font-size: 40px;
    padding-top: 68px;
  }
}

@media (max-width: 480px) {
  .whyUsImg {
    font-size: 40px;
    padding-top: 73px;
  }
}
